import { useState } from "react";
import { Button, Card, InputGroup } from "reactstrap";
import { Table, Input, Container } from "reactstrap";
import { FaCopy } from "react-icons/fa";
import BigNumber from "bignumber.js";

function App() {
  const [rawInvoice, setRawInvoice] = useState("");

  const handleClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      setRawInvoice(text);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      <Card
        body
        style={{
          maxWidth: 400,
          borderRadius: 25,
        }}
        className="shadow border-0 mt-3 mx-auto"
      >
        <h1 className="text-center">Pix2Lightning</h1>
        <InputGroup className="mt-3">
          <Input
            value={rawInvoice}
            onChange={({ target }) => setRawInvoice(target.value)}
            placeholder="Insira aqui a sua invoice lightning."
            type="text"
            name="invoice"
            required
          />
          <Button color="primary" onClick={() => handleClipboard()}>
            <FaCopy />
          </Button>
        </InputGroup>
        <small>Lightning Invoice</small>
        <InputGroup className="mt-3">
          <Input
            placeholder="Insira aqui seu email"
            type="email"
            name="email"
            required
          />
        </InputGroup>
        <small>Seu email é necessário para informarmos o pagamento</small>

        <Button color="primary" className="mt-3">
          Fazer cotação
        </Button>
      </Card>
    </Container>
  );
}

export default App;
